import React from 'react';

const Footer = () => {
	return (
        <div>
            <footer>
                <div className="footer-content footer-container">
                    <p>CodeBridge &copy; 2024</p>
                    {/* <p>We Unite! we Conquer!</p> */}
                </div>

                {/* <div className='footer-bottom'>
					<p>
						copyright &copy;2021 Sabari Blog 
					</p>
				</div> */}
            </footer>
        </div>
    );
};

export default Footer;
